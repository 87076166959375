import React, { useState, useEffect } from "react";
import format from "format-number";
import Toast from "../Toast/Toast";

import "./range.css";

const CharityShopStepOne = (props) => {
  const {
    loanAmount,
    onHandleLoanAmountChange,
    onLoanTermChange,
    onDemonstrableRepaymentChange,
    exitHandler,
  } = props;

  const [loanTerm, setLoanTerm] = useState(4);
  const [showLoanTerm, setShowLoanTerm] = useState(false);
  const [perWeek, setPerWeek] = useState(0);

  const loanTermHandler = (e) => {
    setLoanTerm(e.target.value);
    onLoanTermChange(e);

    const repayment = `Repayment of ${format({
      truncate: 0,
      noSeperator: true,
    })(loanAmount)} over ${loanTerm} weeks is ${format({
      truncate: 2,
      padRight: 2,
    })(perWeek)} per week.`;

    onDemonstrableRepaymentChange(repayment);
  };

  const loanAmountHandler = (event) => {
    onHandleLoanAmountChange(event.target.value);
  };

  useEffect(() => {
    const loanSniff = () => {
      if (loanAmount > 150) {
        Toast.popError(
          `Unfortunately, we can only offer Charity Shop Card loans up to £150.`,
          { autoClose: true }
        );
        onHandleLoanAmountChange(150);
        setShowLoanTerm(true);
        setPerWeek(loanAmount / loanTerm);
        return false;
      }

      setShowLoanTerm(true);
      setPerWeek(loanAmount / loanTerm);

      //   if (loanAmount > 50) {
      //     setShowLoanTerm(true);

      //     setPerWeek(loanAmount / loanTerm);
      //   } else {
      //     setShowLoanTerm(false);
      //   }
    };

    loanSniff();
  }, [loanAmount, loanTerm, onHandleLoanAmountChange]);

  const renderLoanTerm = () => {
    return (
      <>
        <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
          <h3 className="col-span-3 text-lg mt-8">Loan term</h3>
          <p className="col-span-3 text-base">
            We offer interest free Charity Shop Gift Card loans repayable in up
            to 12 instalments (within one year). Select your repayment period to
            see what the repayment amount is.
          </p>
          <div class="col-span-3">
            <div className="flex flex-col space-y-2 p-2 w-full mb-12">
              <input
                type="range"
                className="w-full bg-transparent appearance-none focus:outline-none focus:ring-0 focus:shadow-none zeropa-range"
                min="1"
                max="12"
                step="1"
                name="term"
                id="term"
                value={loanTerm}
                onChange={loanTermHandler}
              />
              <ul className="flex justify-between w-full">
                <li className="flex justify-center relative w-20">
                  <span className="absolute text-gray-700 text-base">
                    1 instalment
                  </span>
                </li>
                <li className="flex justify-center relative w-20">
                  <span className="absolute text-gray-700 text-base">
                    6 instalments
                  </span>
                </li>
                <li className="flex justify-center relative w-20">
                  <span className="absolute text-gray-700 text-base">
                    12 instalments
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-span-3 lg:col-span-1">
              <div className="text-1xl bg-white p-4 w-full justify-center text-center rounded">
                Repayment of{" "}
                <span className="text-bold">
                  &pound;
                  {format({ truncate: 0, noSeperator: true })(loanAmount)}
                </span>{" "}
                over {loanTerm} instalments is &pound;
                {format({ truncate: 2, padRight: 2 })(perWeek)} per instalment.
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="p-8 bg-slate-100 rounded-lg">
        <h3 class="block w-full text-2xl text-gray-900 font-medium title-font mb-4 leading-none">
          Get started with your Charity Shop Gift Card loan
        </h3>
        <div className="relative">
          <label
            class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
            for="loanAmount"
          >
            How much do you need?
          </label>
          <div className="flex flex-col space-y-2 pt-2 w-full">
            <div class="relative">
              <div className="relative">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <span className="text-1xl">&pound;</span>
                </div>
                <input
                  type="number"
                  numeric="true"
                  id="amount"
                  name="amount"
                  class="block text-1xl p-4 pl-10 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                  value={loanAmount}
                  onChange={loanAmountHandler}
                  placeholder="50"
                  min="0"
                  max="150"
                  onInput={(event) => {
                    event.target.value = event.target.value.replace(
                      /[^0-9]*/g,
                      ""
                    );
                  }}
                  onKeyDown={(event) => {
                    if (event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                  required
                />
              </div>
              <span className="text-sm text-gray-400 pl-2">
                Required. Round numbers only please. Minimum of &pound;10,
                maximum of &pound;150.
              </span>
            </div>
            {showLoanTerm && renderLoanTerm()}
          </div>
        </div>
      </div>
      <div className="p-8 pt-6 bg-slate-100 rounded-lg mt-4">
        <p class="leading-relaxed text-base">To be eligible, you must:</p>
        <ul className="list-disc ml-8 text-base">
          <li>Be over 18</li>
          <li>Live permanently in the United Kingdom</li>
          <li>Provide details of your income and expenditure</li>
        </ul>
      </div>
      <div className="p-8 bg-slate-100 rounded-lg mt-4">
        <div className="flex justify-end">
          <button
            class=" text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg"
            onClick={exitHandler}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default CharityShopStepOne;
