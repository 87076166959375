import React, { useState } from "react";
import Header from "../components/Header/Header";
import yep from "../assets/8.jpg";
import csc from "../assets/CharityShopCard1024x584.webp";
import Footer from "../components/Footer/Footer";
import ApplicationTimeline from "../components/ApplicationTimeline/ApplicationTimeline";
import { useNavigate } from "react-router-dom";
import CharityShopStepOne from "../components/CharityShopStep/CharityShopStepOne";
import CharityShopStepTwo from "../components/CharityShopStep/CharityShopStepTwo";

const CharityShopLoan = () => {
  let navigate = useNavigate();
  let [step, setStep] = useState(1);

  const [loanAmount, setLoanAmount] = useState(100);
  const [loanTerm, setLoanTerm] = useState(12);
  const [demonstrableRepayment, setDemonstrableRepayment] = useState("");

  const onLoanTermChange = (e) => {
    setLoanTerm(e.target.value);
  };
  const onDemonstrableRepaymentChange = (str) => {
    setDemonstrableRepayment(str);
  };

  const onHandleLoanAmountChange = (amount) => {
    setLoanAmount(amount);
  };

  const stepOneExitHandler = () => {
    window.analytics.track(`apply.charityshoploan.step1.complete`);
    setStep(2);
  };

  const stepTwoExitHandler = (id, hash) => {
    window.analytics.track(`apply.charityshoploan.step2.complete`);
    navigate(`/apply/${id}/${hash}`, { replace: false });
  };

  const stepTwoBackHandler = () => {
    setStep(1);
  };

  const stepRenderer = (step) => {
    switch (step) {
      case 1:
        return (
          <CharityShopStepOne
            loanAmount={loanAmount}
            onHandleLoanAmountChange={onHandleLoanAmountChange}
            onLoanTermChange={onLoanTermChange}
            onDemonstrableRepaymentChange={onDemonstrableRepaymentChange}
            exitHandler={stepOneExitHandler}
          />
        );
      case 2:
        return (
          <CharityShopStepTwo
            backHandler={stepTwoBackHandler}
            exitHandler={stepTwoExitHandler}
            term={loanTerm}
            demonstrableRepayment={demonstrableRepayment}
            financeAmount={loanAmount}
          />
        );
      default:
        return (
          <CharityShopStepOne
            loanAmount={loanAmount}
            onHandleLoanAmountChange={onHandleLoanAmountChange}
            exitHandler={stepOneExitHandler}
          />
        );
    }
  };

  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yep} className="min-w-full object-cover" alt="" />
        <div className="container absolute w-full p-8 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bottom-0">
          <img
            src={csc}
            alt="The Charity Shop Card logo"
            className="w-[200px]"
          />
          <h1 class="title-font md:text-4xl text-2xl font-medium text-gray-100 drop-shadow-md">
            Apply for a Charity Shop Gift Card Loan
          </h1>
          <span className="mb-4 leading-tight text-lg drop-shadow text-gray-100 block">
            The only gift card that can be used in multiple charity shops
            nationwide; bringing conscious, sustainable shopping to the high
            street.
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container pt-16 pb-8 px-8 sm:px-0 mx-auto flex flex-wrap">
          <h2 className="text-2xl ml-8">Who are ZeroPA?</h2>
          <p class="leading-relaxed text-base ml-8">
            ZeroPA is a social impact lender that offers financial education and
            tools and crucially interest free loans to the financially
            disadvantaged and vulnerable. We are piloting this scheme with the{" "}
            <a
              href="https://www.charityretail.org.uk/"
              target="_blank"
              rel="noreferrer"
              className="text-purple-600 hover:text-purple-800"
            >
              Charity Retail Association
            </a>{" "}
            and{" "}
            <a
              href="https://thecharityshopgiftcard.co.uk/"
              target="_blank"
              rel="noreferrer"
              className="text-purple-600 hover:text-purple-800"
            >
              The Charity Shop Gift Card
            </a>
            . This partnership has four principle benefits
          </p>
          <ol className="text-base ml-12 pt-4 pb-4">
            <li>1. the customer is helping a good cause</li>
            <li>
              2. items usually donated, now have a 2nd life and avoid land fill
            </li>
            <li>
              3. travel miles are usually less as we tend to donate to and
              purchase from charity shops local to us
            </li>
            <li>
              4. The customer avoids high cost credit. ZeroPA charges no
              interest, no fees and no charges and requires no guarantor. It
              doesn’t give cash loans and only pays for goods and services
              directly, in this case through The Charity Shop Gift Card.
            </li>
          </ol>
          <h2 className="text-2xl ml-8">What is the scheme?</h2>
          <p class="leading-relaxed text-base ml-8 pb-4">
            The scheme operates similarly to "buy now, pay later", enabling
            customers to spread the costs of purchases over a number of
            instalments. Like buy now pay later, the customer doesn't pay
            interest or charges provided that the loan is repaid in the agreed
            time period. The scheme can be used by borrowers to buy high value
            items such as white goods or furniture or essentials like clothing.
          </p>
          <p class="leading-relaxed text-base ml-8">
            Our finance application process couldn't be simpler. Before you
            begin, please have necessary documents to prove your identity (like
            a driving licence, council tax bill or utility bill). We may ask you
            some questions about your income and expenditure, but we only ask
            the minimum questions we need.
          </p>
        </div>
      </section>

      <section class="text-gray-600 body-font mt-8">
        <div class="container mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className="hidden md:block md:w-1/3 pr-10 text-base">
              <ApplicationTimeline atStep={step} />
            </div>
            <div className="w-full md:w-2/3">{stepRenderer(step)}</div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CharityShopLoan;
