import React, { useState, useEffect } from "react";
import format from "format-number";
import { API_URL } from "../../core/constants";
import Toast from "../Toast/Toast";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import applicationSchema from "../../constants/application.schema";
import { useFormik } from "formik";
import ZeropaInput from "../ZeropaInput/ZeropaInput";
import { BRAND } from "../../constants/brand";

dayjs.extend(customParseFormat);

const CharityShopStepTwo = (props) => {
  const {
    exitHandler,
    backHandler,
    financeAmount,
    term,
    demonstrableRepayment,
  } = props;

  const [loading, setLoading] = useState(false);

  const [weeklyEmployment, setWeeklyEmployment] = useState();
  const [monthlyEmployment, setMonthlyEmployment] = useState();

  const [weeklyESA, setWeeklyESA] = useState();
  const [monthlyESA, setMonthlyESA] = useState();

  const [weeklyUniversalCredit, setWeeklyUniversalCredit] = useState();
  const [monthlyUniversalCredit, setMonthlyUniversalCredit] = useState();

  const [weeklyChildBenefit, setWeeklyChildBenefit] = useState();
  const [monthlyChildBenefit, setMonthlyChildBenefit] = useState();

  const [weeklyOtherIncome, setWeeklyOtherIncome] = useState();
  const [monthlyOtherIncome, setMonthlyOtherIncome] = useState();

  const [weeklyRent, setWeeklyRent] = useState();
  const [monthlyRent, setMonthlyRent] = useState();

  const [weeklyFood, setWeeklyFood] = useState();
  const [monthlyFood, setMonthlyFood] = useState();

  const [weeklyElectricity, setWeeklyElectricity] = useState();
  const [monthlyElectricity, setMonthlyElectricity] = useState();

  const [weeklyGas, setWeeklyGas] = useState();
  const [monthlyGas, setMonthlyGas] = useState();

  const [weeklyWater, setWeeklyWater] = useState();
  const [monthlyWater, setMonthlyWater] = useState();

  const [weeklyCouncilTax, setWeeklyCouncilTax] = useState();
  const [monthlyCouncilTax, setMonthlyCouncilTax] = useState();

  const [weeklyMobilePhone, setWeeklyMobilePhone] = useState();
  const [monthlyMobilePhone, setMonthlyMobilePhone] = useState();

  const [weeklyTelephone, setWeeklyTelephone] = useState();
  const [monthlyTelephone, setMonthlyTelephone] = useState();

  const [weeklyBroadband, setWeeklyBroadband] = useState();
  const [monthlyBroadband, setMonthlyBroadband] = useState();

  const [weeklyInsurance, setWeeklyInsurance] = useState();
  const [monthlyInsurance, setMonthlyInsurance] = useState();

  const [weeklyTransport, setWeeklyTransport] = useState();
  const [monthlyTransport, setMonthlyTransport] = useState();

  const [weeklyCredit, setWeeklyCredit] = useState();
  const [monthlyCredit, setMonthlyCredit] = useState();

  const [weeklyOther, setWeeklyOther] = useState();
  const [monthlyOther, setMonthlyOther] = useState();

  const [arrearsRent, setArrearsRent] = useState();
  const [arrearsElectricity, setArrearsElectricity] = useState();
  const [arrearsGas, setArrearsGas] = useState();
  const [arrearsCouncilTax, setArrearsCouncilTax] = useState();
  const [arrearsWater, setArrearsWater] = useState();
  const [arrearsCredit, setArrearsCredit] = useState();
  const [arrearsMobile, setArrearsMobile] = useState();
  const [arrearsOther, setArrearsOther] = useState();

  const [otherIncomeDescription, setOtherIncomeDescription] = useState("");
  const [otherExpenditureDescription, setOtherExpenditureDescription] =
    useState("");
  const [showOtherIncomeDescription, setShowOtherIncomeDescription] =
    useState(false);
  const [showOtherExpenditureDescription, setShowOtherExpenditureDescription] =
    useState(false);

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenditure, setTotalExpenditure] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);

  const [showCantAfford, setShowCantAfford] = useState(false);

  useEffect(() => {
    const calculateIncome = () => {
      const income =
        parseInt(monthlyEmployment || 0) +
        parseInt(monthlyESA || 0) +
        parseInt(monthlyUniversalCredit || 0) +
        parseInt(monthlyChildBenefit || 0) +
        parseInt(monthlyOtherIncome || 0);
      setTotalIncome(income);
      return income;
    };
    const calculateExpenses = () => {
      const expenditure =
        parseInt(monthlyRent || 0) +
        parseInt(monthlyFood || 0) +
        parseInt(monthlyElectricity || 0) +
        parseInt(monthlyGas || 0) +
        parseInt(monthlyWater || 0) +
        parseInt(monthlyCouncilTax || 0) +
        parseInt(monthlyMobilePhone || 0) +
        parseInt(monthlyBroadband || 0) +
        parseInt(monthlyTelephone || 0) +
        parseInt(monthlyInsurance || 0) +
        parseInt(monthlyTransport || 0) +
        parseInt(monthlyCredit || 0) +
        parseInt(monthlyOther || 0);
      setTotalExpenditure(expenditure);
      return expenditure;
    };
    const calculateRemaining = () => {
      const remaining = calculateIncome() - calculateExpenses();
      setTotalRemaining(remaining);
      return remaining;
    };
    const canAfford = () => {
      if (calculateRemaining() < 0) {
        setShowCantAfford(true);
      } else {
        setShowCantAfford(false);
      }
    };

    calculateIncome();
    calculateExpenses();
    calculateRemaining();
    canAfford();
  }, [
    monthlyEmployment,
    monthlyESA,
    monthlyUniversalCredit,
    monthlyChildBenefit,
    monthlyOtherIncome,
    monthlyRent,
    monthlyFood,
    monthlyElectricity,
    monthlyGas,
    monthlyWater,
    monthlyCouncilTax,
    monthlyMobilePhone,
    monthlyBroadband,
    monthlyTelephone,
    monthlyInsurance,
    monthlyTransport,
    monthlyCredit,
    monthlyOther,
  ]);

  const aggregator = ({ weekly, monthly }) => {
    let calculated = 0;
    if (weekly) {
      calculated = weekly * 4.4;
      return {
        weekly: weekly,
        monthly: calculated,
      };
    } else {
      calculated = monthly / 4.4;
      return {
        weekly: calculated,
        monthly: monthly,
      };
    }
  };

  const dobValidator = (dobDay, dobMonth, dobYear) => {
    let givenDob = `${dobYear}-${dobMonth}-${dobDay}`;

    const dob = dayjs(givenDob, "YYYY-M-D");
    const eighteenYearsAgo = dayjs().subtract(18, "year");
    const isValid = dayjs(givenDob, "YYYY-M-D", true).isValid();
    if (!isValid) {
      Toast.popError(
        `The date of birth provided doesn't look right, please check and try again.`,
        { autoClose: false }
      );
      return false;
    }
    if (dob.isAfter(eighteenYearsAgo)) {
      Toast.popError(
        `You must be at least 18 years old to apply for our products.`,
        { autoClose: false }
      );
      return false;
    }
    return true;
  };

  //------------------------------------
  const onWeeklyEmploymentHandler = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyEmployment(c.weekly);
    setMonthlyEmployment(c.monthly);
  };

  const onMonthlyEmploymentHandler = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyEmployment(c.weekly);
    setMonthlyEmployment(c.monthly);
  };

  const onWeeklyESAHandler = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyESA(c.weekly);
    setMonthlyESA(c.monthly);
  };

  const onMonthlyESAHandler = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyESA(c.weekly);
    setMonthlyESA(c.monthly);
  };

  const onWeeklyUniversalCredit = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyUniversalCredit(c.weekly);
    setMonthlyUniversalCredit(c.monthly);
  };

  const onMonthlyUniversalCredit = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyUniversalCredit(c.weekly);
    setMonthlyUniversalCredit(c.monthly);
  };

  const onWeeklyChildBenefit = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyChildBenefit(c.weekly);
    setMonthlyChildBenefit(c.monthly);
  };

  const onMonthlyChildBenefit = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyChildBenefit(c.weekly);
    setMonthlyChildBenefit(c.monthly);
  };

  const onWeeklyOtherIncome = (event) => {
    let c = aggregator({ weekly: event.target.value });
    if (event.target.value.length > 0) {
      setShowOtherIncomeDescription(true);
    } else {
      setShowOtherIncomeDescription(false);
    }
    setWeeklyOtherIncome(c.weekly);
    setMonthlyOtherIncome(c.monthly);
  };

  const onMonthlyOtherIncome = (event) => {
    let c = aggregator({ monthly: event.target.value });
    if (event.target.value.length > 0) {
      setShowOtherIncomeDescription(true);
    } else {
      setShowOtherIncomeDescription(false);
    }
    setWeeklyOtherIncome(c.weekly);
    setMonthlyOtherIncome(c.monthly);
  };

  const onWeeklyRent = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyRent(c.weekly);
    setMonthlyRent(c.monthly);
  };

  const onMonthlyRent = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyRent(c.weekly);
    setMonthlyRent(c.monthly);
  };

  const onWeeklyFood = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyFood(c.weekly);
    setMonthlyFood(c.monthly);
  };

  const onMonthlyFood = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyFood(c.weekly);
    setMonthlyFood(c.monthly);
  };

  const onWeeklyElectricity = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyElectricity(c.weekly);
    setMonthlyElectricity(c.monthly);
  };

  const onMonthlyElectricity = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyElectricity(c.weekly);
    setMonthlyElectricity(c.monthly);
  };

  const onWeeklyGas = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyGas(c.weekly);
    setMonthlyGas(c.monthly);
  };

  const onMonthlyGas = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyGas(c.weekly);
    setMonthlyGas(c.monthly);
  };

  const onWeeklyWater = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyWater(c.weekly);
    setMonthlyWater(c.monthly);
  };

  const onMonthlyWater = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyWater(c.weekly);
    setMonthlyWater(c.monthly);
  };

  const onWeeklyCouncilTax = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyCouncilTax(c.weekly);
    setMonthlyCouncilTax(c.monthly);
  };

  const onMonthlyCouncilTax = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyCouncilTax(c.weekly);
    setMonthlyCouncilTax(c.monthly);
  };

  const onWeeklyMobilePhone = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyMobilePhone(c.weekly);
    setMonthlyMobilePhone(c.monthly);
  };

  const onMonthlyMobilePhone = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyMobilePhone(c.weekly);
    setMonthlyMobilePhone(c.monthly);
  };

  const onWeeklyTelephone = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyTelephone(c.weekly);
    setMonthlyTelephone(c.monthly);
  };

  const onMonthlyTelephone = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyTelephone(c.weekly);
    setMonthlyTelephone(c.monthly);
  };

  const onWeeklyBroadband = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyBroadband(c.weekly);
    setMonthlyBroadband(c.monthly);
  };

  const onMonthlyBroadband = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyBroadband(c.weekly);
    setMonthlyBroadband(c.monthly);
  };

  const onWeeklyInsurance = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyInsurance(c.weekly);
    setMonthlyInsurance(c.monthly);
  };

  const onMonthlyInsurance = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyInsurance(c.weekly);
    setMonthlyInsurance(c.monthly);
  };

  const onWeeklyTransport = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyTransport(c.weekly);
    setMonthlyTransport(c.monthly);
  };

  const onMonthlyTransport = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyTransport(c.weekly);
    setMonthlyTransport(c.monthly);
  };

  const onWeeklyCredit = (event) => {
    let c = aggregator({ weekly: event.target.value });
    setWeeklyCredit(c.weekly);
    setMonthlyCredit(c.monthly);
  };

  const onMonthlyCredit = (event) => {
    let c = aggregator({ monthly: event.target.value });
    setWeeklyCredit(c.weekly);
    setMonthlyCredit(c.monthly);
  };

  const onWeeklyOther = (event) => {
    let c = aggregator({ weekly: event.target.value });
    if (event.target.value.length > 0) {
      setShowOtherExpenditureDescription(true);
    } else {
      setShowOtherExpenditureDescription(false);
    }
    setWeeklyOther(c.weekly);
    setMonthlyOther(c.monthly);
  };

  const onMonthlyOther = (event) => {
    let c = aggregator({ monthly: event.target.value });
    if (event.target.value.length > 0) {
      setShowOtherExpenditureDescription(true);
    } else {
      setShowOtherExpenditureDescription(false);
    }
    setWeeklyOther(c.weekly);
    setMonthlyOther(c.monthly);
  };

  const onArrearsRent = (event) => {
    setArrearsRent(event.target.value);
  };

  const onArrearsElectricity = (event) => {
    setArrearsElectricity(event.target.value);
  };

  const onArrearsGas = (event) => {
    setArrearsGas(event.target.value);
  };

  const onArrearsCouncilTax = (event) => {
    setArrearsCouncilTax(event.target.value);
  };

  const onArrearsWater = (event) => {
    setArrearsWater(event.target.value);
  };

  const onArrearsCredit = (event) => {
    setArrearsCredit(event.target.value);
  };

  const onArrearsMobile = (event) => {
    setArrearsMobile(event.target.value);
  };

  const onArrearsOther = (event) => {
    setArrearsOther(event.target.value);
  };

  const onOtherIncomeDescription = (event) => {
    setOtherIncomeDescription(event.target.value);
  };

  const onOtherExpenditureDescription = (event) => {
    setOtherExpenditureDescription(event.target.value);
  };
  // --------------------------------------

  const renderLoading = () => {
    return (
      <section>
        <div className="p-8 bg-slate-100 rounded-lg">
          <div className="w-full">
            <span className="block text-gray-900 text-center text-2xl animate-pulse">
              Hold on, we're just thinking about things, please wait...
            </span>
          </div>
        </div>
      </section>
    );
  };

  let referralInitial = "csgc";

  const formik = useFormik({
    initialValues: {
      title: "Mr",
      firstName: "",
      lastName: "",
      emailAddress: "",
      addressStreet: "",
      addressStreet2: "",
      addressCity: "",
      addressCounty: "",
      addressPostcode: "",
      dobDay: "1",
      dobMonth: "1",
      dobYear: "1992",
      phoneNumber: "",
      financePurpose: "csgc",
      referral: referralInitial,
    },
    validationSchema: applicationSchema,
    onSubmit: (values) => {
      console.log("onSubmit", values);

      window.analytics.track(`apply.charityshoploan.step2.click`, {
        amount: financeAmount,
        term: term,
      });

      setLoading(true);

      if (!dobValidator(values.dobDay, values.dobMonth, values.dobYear)) {
        setLoading(false);
        return false;
      }

      let income = {
        totalIncome: totalIncome,
        employment: {
          weekly: weeklyEmployment || 0,
          monthly: monthlyEmployment || 0,
        },
        esa: {
          weekly: weeklyESA || 0,
          monthly: monthlyESA || 0,
        },
        universalCredit: {
          weekly: weeklyUniversalCredit || 0,
          monthly: monthlyUniversalCredit || 0,
        },
        childBenefit: {
          weekly: weeklyChildBenefit || 0,
          monthly: monthlyChildBenefit || 0,
        },
        otherIncome: {
          weekly: weeklyOtherIncome || 0,
          monthly: monthlyOtherIncome || 0,
          description: otherIncomeDescription || "",
        },
      };

      let expenditure = {
        totalExpenditure: totalExpenditure,
        rent: {
          weekly: weeklyRent || 0,
          monthly: monthlyRent || 0,
        },
        food: {
          weekly: weeklyFood || 0,
          monthly: monthlyFood || 0,
        },
        electricity: {
          weekly: weeklyElectricity || 0,
          monthly: monthlyElectricity || 0,
        },
        gas: {
          weekly: weeklyGas || 0,
          monthly: monthlyGas || 0,
        },
        water: {
          weekly: weeklyWater || 0,
          monthly: monthlyWater || 0,
        },
        councilTax: {
          weekly: weeklyCouncilTax || 0,
          monthly: monthlyCouncilTax || 0,
        },
        mobilePhone: {
          weekly: weeklyMobilePhone || 0,
          monthly: monthlyMobilePhone || 0,
        },
        telephone: {
          weekly: weeklyTelephone || 0,
          monthly: monthlyTelephone || 0,
        },
        broadband: {
          weekly: weeklyBroadband || 0,
          monthly: monthlyBroadband || 0,
        },
        insurance: {
          weekly: weeklyInsurance || 0,
          monthly: monthlyInsurance || 0,
        },
        transport: {
          weekly: weeklyTransport || 0,
          monthly: monthlyTransport || 0,
        },
        credit: {
          weekly: weeklyCredit || 0,
          monthly: monthlyCredit || 0,
        },
        other: {
          weekly: weeklyOther || 0,
          monthly: monthlyOther || 0,
          description: otherExpenditureDescription || "",
        },
      };

      let arrears = {
        rent: arrearsRent || 0,
        electricity: arrearsElectricity || 0,
        gas: arrearsGas || 0,
        water: arrearsWater || 0,
        councilTax: arrearsCouncilTax || 0,
        credit: arrearsCredit || 0,
        mobile: arrearsMobile || 0,
        other: arrearsOther || 0,
      };

      let address = {
        line1: values.addressStreet,
        line2: values.addressStreet2,
        city: values.addressCity,
        county: values.addressCounty,
        postcode: values.addressPostcode,
      };

      let applicant = {
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        address: address,
        email: values.emailAddress,
        phoneNumber: values.phoneNumber,
        dob: {
          day: values.dobDay,
          month: values.dobMonth,
          year: values.dobYear,
        },
        referral: values.referral,
        financePurpose: values.financePurpose,
        term: term || 0,
        demonstrableRepayment: demonstrableRepayment || "",
        source: "website",
      };

      let payload = {
        amount: financeAmount,
        remaining: totalRemaining,
        brand: BRAND,
        applicant: applicant,
        iande: {
          income: income,
          expenditure: expenditure,
          arrears: arrears,
        },
      };

      console.log("payload", payload);

      axios
        .post(`${API_URL}/apply`, payload)
        .then(function (response) {
          console.log("Success", response);
          console.log("id", response.data.body._id);

          exitHandler(response.data.body._id, response.data.body.hash);
        })
        .catch(function (error) {
          Toast.popError(
            "Oh no, something has gone wrong. Please try again or contact us if this continues.",
            { autoClose: false }
          );
          console.log(error);
          setLoading(false);
        });
    },
  });

  const renderCantAfford = () => {
    return (
      <>
        <div className="col-span-3 rounded bg-red-300 p-4 mt-2">
          <div className="flex">
            <span className="flex-1 text-base text-black">
              We're sorry, based on your circumstances, we cannot offer you a
              loan because it is unaffordable.
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        renderLoading()
      ) : (
        <section>
          <div className="mb-8 text-base">
            <div className="bg-indigo-600 rounded">
              <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                  <div className="w-0 flex-1 flex items-center">
                    <p className="ml-3 font-medium text-white truncate">
                      <span className="hidden lg:inline">
                        Great. Let's get some details for your &pound;
                        {financeAmount} Gift Card loan.
                      </span>
                      <span className="lg:hidden">
                        Great, let's apply for &pound;{financeAmount}.
                      </span>
                    </p>
                  </div>
                  <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                    <button
                      onClick={backHandler}
                      className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
                    >
                      <span className="hidden lg:inline">
                        Need to change the amount? Go back 👈
                      </span>
                      <span className="lg:hidden">Go back 👈</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-8 bg-slate-100 rounded-lg">
              <h3 className="block w-full text-2xl text-gray-900 font-medium title-font mb-4 leading-none">
                Personal details
              </h3>
              <div className="relative">
                <div className="grid gap-6 mb-6 grid-cols-1 lg:grid-cols-3">
                  <div className="col-span-3 lg:col-span-1">
                    <label
                      htmlFor="title"
                      className="block mb-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Title *
                    </label>
                    <select
                      id="title"
                      name="title"
                      className="bg-gray-50 border p-3 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    >
                      <option value="Mr" selected>
                        Mr
                      </option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms">Ms</option>
                      <option value="Dr">Doctor</option>
                    </select>
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <ZeropaInput
                      label="First name"
                      id="firstName"
                      formik={formik}
                      placeholder="John"
                      required
                    />
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <ZeropaInput
                      label="Last name"
                      id="lastName"
                      formik={formik}
                      placeholder="Appleseed"
                      required
                    />
                  </div>
                  <h3 className="col-span-3 text-lg">Address</h3>
                  <div className="col-span-3">
                    <ZeropaInput
                      label="Street"
                      id="addressStreet"
                      formik={formik}
                      placeholder="10 Dover Avenue"
                      required
                    />
                  </div>
                  <div className="col-span-3">
                    <ZeropaInput
                      label="Street #2"
                      id="addressStreet2"
                      formik={formik}
                      placeholder="Clapham"
                    />
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <ZeropaInput
                      label="Town/City"
                      id="addressCity"
                      formik={formik}
                      placeholder="London"
                      required
                    />
                  </div>
                  <div className="col-span-3 lg:col-span-1">
                    <ZeropaInput
                      label="County"
                      id="addressCounty"
                      formik={formik}
                      placeholder=""
                    />
                  </div>
                  <div className="col-span-3 lg:col-span-1 mb-6">
                    <ZeropaInput
                      label="Postcode"
                      id="addressPostcode"
                      formik={formik}
                      placeholder="W1 2ND"
                      required
                    />
                  </div>

                  <h3 className="col-span-3 text-lg">Date of Birth</h3>
                  <div className="col-span-1">
                    <label
                      htmlFor="dobDay"
                      className="block mb-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Day
                    </label>
                    <select
                      id="dobDay"
                      name="dobDay"
                      className="bg-gray-50 border p-3 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dobDay}
                    >
                      <option value="1" selected>
                        1st
                      </option>
                      <option value="2">2nd</option>
                      <option value="3">3rd</option>
                      <option value="4">4th</option>
                      <option value="5">5th</option>
                      <option value="6">6th</option>
                      <option value="7">7th</option>
                      <option value="8">8th</option>
                      <option value="9">9th</option>
                      <option value="10">10th</option>
                      <option value="11">11th</option>
                      <option value="12">12th</option>
                      <option value="13">13th</option>
                      <option value="14">14th</option>
                      <option value="15">15th</option>
                      <option value="16">16th</option>
                      <option value="17">17th</option>
                      <option value="18">18th</option>
                      <option value="19">19th</option>
                      <option value="20">20th</option>
                      <option value="21">21st</option>
                      <option value="22">22nd</option>
                      <option value="23">23rd</option>
                      <option value="24">24th</option>
                      <option value="25">25th</option>
                      <option value="26">26th</option>
                      <option value="27">27th</option>
                      <option value="28">28th</option>
                      <option value="29">29th</option>
                      <option value="30">30th</option>
                      <option value="31">31st</option>
                    </select>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="dobMonth"
                      className="block mb-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Month
                    </label>
                    <select
                      id="dobMonth"
                      name="dobMonth"
                      className="bg-gray-50 border p-3 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dobMonth}
                    >
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="dobYear"
                      className="block mb-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Year
                    </label>
                    <select
                      id="dobYear"
                      name="dobYear"
                      className="bg-gray-50 border p-3 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dobYear}
                    >
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1999">1999</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>
                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                      <option value="1970">1970</option>
                      <option value="1969">1969</option>
                      <option value="1968">1968</option>
                      <option value="1967">1967</option>
                      <option value="1966">1966</option>
                      <option value="1965">1965</option>
                      <option value="1964">1964</option>
                      <option value="1963">1963</option>
                      <option value="1962">1962</option>
                      <option value="1961">1961</option>
                      <option value="1960">1960</option>
                      <option value="1959">1959</option>
                      <option value="1958">1958</option>
                      <option value="1957">1957</option>
                      <option value="1956">1956</option>
                      <option value="1955">1955</option>
                      <option value="1954">1954</option>
                      <option value="1953">1953</option>
                      <option value="1952">1952</option>
                      <option value="1951">1951</option>
                      <option value="1950">1950</option>
                      <option value="1949">1949</option>
                      <option value="1948">1948</option>
                      <option value="1947">1947</option>
                      <option value="1946">1946</option>
                      <option value="1945">1945</option>
                      <option value="1944">1944</option>
                      <option value="1943">1943</option>
                      <option value="1942">1942</option>
                      <option value="1941">1941</option>
                      <option value="1940">1940</option>
                      <option value="1939">1939</option>
                      <option value="1938">1938</option>
                      <option value="1937">1937</option>
                      <option value="1936">1936</option>
                      <option value="1935">1935</option>
                    </select>
                  </div>
                </div>
                <h3 className="col-span-3 text-lg mt-8">Contact Details</h3>
                <div class="mb-6 mt-8">
                  <ZeropaInput
                    label="Email"
                    id="emailAddress"
                    formik={formik}
                    placeholder="john.appleseed@mail.com"
                    required
                  />
                </div>
                <div class="mb-6">
                  <ZeropaInput
                    label="Phone Number"
                    id="phoneNumber"
                    formik={formik}
                    placeholder="07654 321000"
                    required
                  />
                  <span className="text-sm text-gray-400 pl-2">
                    We take your phone number to contact you in the event of a
                    problem with your application.
                  </span>
                </div>
                <h3 className="col-span-3 text-lg mt-8">
                  Other required details
                </h3>
                <div class="mb-6 mt-8">
                  <label
                    htmlFor="financePurpose"
                    className="block mb-2 text-base font-medium text-gray-900 dark:text-gray-300"
                  >
                    What do you need finance for? *
                  </label>
                  <select
                    id="financePurpose"
                    name="financePurpose"
                    className="bg-gray-50 border p-3 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                    value={formik.values.financePurpose}
                    disabled={true}
                  >
                    <option value="csgc" selected>
                      Charity Shop Gift Card
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="w-1/3 pr-10 text-base"> </div>
            <div className="p-8 bg-slate-100 rounded-lg mt-8">
              <h3 class="block w-full text-2xl text-gray-900 font-medium title-font mb-4 leading-none">
                Income &amp; expenditure
              </h3>
              <p className="text-base mb-8">
                We ask the minimum amount of questions possible for us to
                process your application. Please be as detailed as possible,
                provide higher amounts for variable bills or amounts and
                round-up to the nearest pound - don't worry about the pennies on
                things. Your answers are strictly confidential.
              </p>
              <div className="relative">
                <div class="grid gap-6 mb-6 lg:grid-cols-3">
                  <div>
                    <h4>Income</h4>
                  </div>
                  <div>Weekly</div>
                  <div>Monthly</div>
                  {/* Employment */}
                  <div>
                    <label
                      htmlFor="weeklyEmployment"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Employment
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weeklyEmployment"
                        name="weeklyEmployment"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyEmploymentHandler}
                        value={format({ truncate: 0 })(weeklyEmployment || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthlyEmployment"
                        name="monthlyEmployment"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyEmploymentHandler}
                        value={format({ truncate: 0 })(monthlyEmployment || 0)}
                      />
                    </div>
                  </div>
                  {/* Benefits: ESA */}
                  <div>
                    <label
                      htmlFor="weeklyESA"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      ESA
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weeklyESA"
                        name="weeklyESA"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyESAHandler}
                        value={format({ truncate: 0 })(weeklyESA || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthlyESA"
                        name="monthlyESA"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyESAHandler}
                        value={format({ truncate: 0 })(monthlyESA || 0)}
                      />
                    </div>
                  </div>
                  {/* Benefits: Universal Credit */}
                  <div>
                    <label
                      htmlFor="weeklyUniversalCredit"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Universal Credit
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        id="weeklyUniversalCredit"
                        name="weeklyUniversalCredit"
                        inputmode="numeric"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyUniversalCredit}
                        value={format({ truncate: 0 })(
                          weeklyUniversalCredit || 0
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthlyUniversalCredit"
                        name="monthlyUniversalCredit"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyUniversalCredit}
                        value={format({ truncate: 0 })(
                          monthlyUniversalCredit || 0
                        )}
                      />
                    </div>
                  </div>
                  {/* Benefits: Child Benefit */}
                  <div>
                    <label
                      htmlFor="weeklyChildBenefit"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Child Benefit
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        id="weeklyChildBenefit"
                        name="weeklyChildBenefit"
                        inputmode="numeric"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyChildBenefit}
                        value={format({ truncate: 0 })(weeklyChildBenefit || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        id="monthlyChildBenefit"
                        name="monthlyChildBenefit"
                        inputmode="numeric"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyChildBenefit}
                        value={format({ truncate: 0 })(
                          monthlyChildBenefit || 0
                        )}
                      />
                    </div>
                  </div>
                  {/* Other */}
                  <div>
                    <label
                      htmlFor="weeklyOtherIncome"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Other Income
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        id="weeklyOtherIncome"
                        name="weeklyOtherIncome"
                        inputmode="numeric"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyOtherIncome}
                        value={format({ truncate: 0 })(weeklyOtherIncome || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        id="monthlyOtherIncome"
                        name="monthlyOtherIncome"
                        inputmode="numeric"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyOtherIncome}
                        value={format({ truncate: 0 })(monthlyOtherIncome || 0)}
                      />
                    </div>
                  </div>
                  {/* Other */}
                  {showOtherIncomeDescription && (
                    <>
                      <div>
                        <label
                          htmlFor="weeklyOtherIncome"
                          class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                        >
                          Where is that other income from?
                        </label>
                      </div>
                      <div className="col-span-2">
                        <div className="relative">
                          <input
                            type="text"
                            id="otherIncomeDescription"
                            name="otherIncomeDescription"
                            className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                            onChange={onOtherIncomeDescription}
                            value={otherIncomeDescription}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/* Family */}

                  {/* Total Income */}
                  <div className="col-span-3 rounded bg-slate-300 p-4">
                    <div className="flex">
                      <span className="flex-1 text-lg text-green-900">
                        Your total monthly income
                      </span>
                      <span className="flex-1 text-lg text-green-900 text-right">
                        {format({ prefix: "£" })(totalIncome)}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Food shop */}
                {/* Electricity */}
                {/* Gas */}
                {/* Water */}
                {/* Council Tax */}
                {/* Rent */}
                {/* Mobile Phone */}
                {/* Telephone */}
                {/* Broadband */}
                {/* Clothes */}
                {/* Transport */}
                {/* Other */}
                {/* Insurance */}
                {/* Stepchange */}

                {/* Arrears */}
                {/* Rent */}
                {/* Electricity */}
                {/* Gas */}
                {/* Water */}
                {/* Council Tax */}
                {/* Mobile */}
                {/* Loans */}
                {/* Other */}

                <div class="grid gap-6 mb-6 lg:grid-cols-3">
                  <div>
                    <h4>Expenditure</h4>
                  </div>
                  <div>Weekly</div>
                  <div>Monthly</div>
                  {/* Rent/Mortgage */}
                  <div>
                    <label
                      for="weekly_rent"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Rent/Mortgage
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_rent"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyRent}
                        value={format({ truncate: 0 })(weeklyRent || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_rent"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyRent}
                        value={format({ truncate: 0 })(monthlyRent || 0)}
                      />
                    </div>
                  </div>
                  {/* Groceries */}
                  <div>
                    <label
                      for="weekly_food"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Groceries/Food
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_food"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyFood}
                        value={format({ truncate: 0 })(weeklyFood || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_food"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyFood}
                        value={format({ truncate: 0 })(monthlyFood || 0)}
                      />
                    </div>
                  </div>
                  {/* Electricity */}
                  <div>
                    <label
                      for="weekly_electricity"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Electricity
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_electricity"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyElectricity}
                        value={format({ truncate: 0 })(weeklyElectricity || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_electricity"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyElectricity}
                        value={format({ truncate: 0 })(monthlyElectricity || 0)}
                      />
                    </div>
                  </div>
                  {/* Gas */}
                  <div>
                    <label
                      for="weekly_gas"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Gas
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_gas"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyGas}
                        value={format({ truncate: 0 })(weeklyGas || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_gas"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyGas}
                        value={format({ truncate: 0, noSeperator: true })(
                          monthlyGas || 0
                        )}
                      />
                    </div>
                  </div>
                  {/* Water */}
                  <div>
                    <label
                      for="weekly_water"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Water
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_water"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyWater}
                        value={format({ truncate: 0 })(weeklyWater || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_water"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyWater}
                        value={format({ truncate: 0 })(monthlyWater || 0)}
                      />
                    </div>
                  </div>
                  {/* Council Tax */}
                  <div>
                    <label
                      for="weekly_council_tax"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Council Tax
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_council_tax"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyCouncilTax}
                        value={format({ truncate: 0 })(weeklyCouncilTax || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_council_tax"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyCouncilTax}
                        value={format({ truncate: 0 })(monthlyCouncilTax || 0)}
                      />
                    </div>
                  </div>
                  {/* Mobile Phone */}
                  <div>
                    <label
                      for="weekly_mobile_phone"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Mobile Phone
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_mobile_phone"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyMobilePhone}
                        value={format({ truncate: 0 })(weeklyMobilePhone || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_mobile_phone"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyMobilePhone}
                        value={format({ truncate: 0 })(monthlyMobilePhone || 0)}
                      />
                    </div>
                  </div>
                  {/* Telephone */}
                  <div>
                    <label
                      for="weekly_telephone"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Telephone
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_telephone"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyTelephone}
                        value={format({ truncate: 0 })(weeklyTelephone || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_telephone"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyTelephone}
                        value={format({ truncate: 0 })(monthlyTelephone || 0)}
                      />
                    </div>
                  </div>
                  {/* Broadband */}
                  <div>
                    <label
                      for="weekly_broadband"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Internet
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_broadband"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyBroadband}
                        value={format({ truncate: 0 })(weeklyBroadband || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_broadband"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyBroadband}
                        value={format({ truncate: 0 })(monthlyBroadband || 0)}
                      />
                    </div>
                  </div>
                  {/* Insurance */}
                  <div>
                    <label
                      for="weekly_insurance"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300 sm:truncate"
                    >
                      Insurance
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_insurance"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyInsurance}
                        value={format({ truncate: 0 })(weeklyInsurance || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_insurance"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyInsurance}
                        value={format({ truncate: 0 })(monthlyInsurance || 0)}
                      />
                    </div>
                  </div>
                  {/* Transport */}
                  <div>
                    <label
                      for="weekly_transport"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300 sm:truncate"
                    >
                      Transport
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_transport"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyTransport}
                        value={format({ truncate: 0 })(weeklyTransport || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_transport"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyTransport}
                        value={format({ truncate: 0 })(monthlyTransport || 0)}
                      />
                    </div>
                  </div>
                  {/* Loans/Credit etc. */}
                  <div>
                    <label
                      for="weekly_credit"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300 sm:truncate"
                    >
                      Credit repayments
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_credit"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyCredit}
                        value={format({ truncate: 0 })(weeklyCredit || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_credit"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyCredit}
                        value={format({ truncate: 0 })(monthlyCredit || 0)}
                      />
                    </div>
                  </div>
                  {/* Other */}
                  <div>
                    <label
                      for="weekly_other"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Other expenses
                    </label>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="weekly_other"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 weekly"
                        onChange={onWeeklyOther}
                        value={format({ truncate: 0 })(weeklyOther || 0)}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="monthly_other"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0 monthly"
                        onChange={onMonthlyOther}
                        value={format({ truncate: 0 })(monthlyOther || 0)}
                      />
                    </div>
                  </div>

                  {showOtherExpenditureDescription && (
                    <>
                      <div>
                        <label
                          htmlFor="otherExpenditureDescription"
                          className="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                        >
                          Where does that other expenditure go?
                        </label>
                      </div>
                      <div className="col-span-2">
                        <div className="relative">
                          <input
                            type="text"
                            id="otherExpenditureDescription"
                            name="otherExpenditureDescription"
                            className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                            onChange={onOtherExpenditureDescription}
                            value={otherExpenditureDescription}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/* Total Expenditure */}
                  <div className="col-span-3 rounded bg-slate-300 p-4">
                    <div className="flex">
                      <span className="flex-1 text-lg text-red-900">
                        Your total monthly expenditure
                      </span>
                      <span className="flex-1 text-lg text-red-900 text-right">
                        {format({ prefix: "£" })(totalExpenditure)}
                      </span>
                    </div>
                  </div>

                  {/* Totals */}
                  <div className="col-span-3 rounded bg-slate-300 p-4 mt-2">
                    <div className="flex mb-2">
                      <span className="flex-1 text-lg text-black">
                        Remaining income after expenditure
                      </span>
                      <span className="flex-1 text-lg text-black text-right">
                        {format({ prefix: "£" })(totalRemaining)}
                      </span>
                    </div>
                    <span className="text-sm text-slate-800">
                      This is how much you have left each month. Please check
                      your figures if it looks wrong.
                    </span>
                  </div>

                  {/* Warning */}
                  {showCantAfford && renderCantAfford()}
                </div>
              </div>
            </div>
            <div className="w-1/3 pr-10 text-base"> </div>
            <div className="p-8 bg-slate-100 rounded-lg mt-8">
              <h3 class="block w-full text-2xl text-gray-900 font-medium title-font mb-4 leading-none">
                Arrears
              </h3>
              <p className="text-base mb-8">
                If you have any arrears, please enter the details of each here.
                We're here to help and having a clear picture of your situation
                is important for us to be able to be effective. You can skip
                this section if you do not have any arrears.
              </p>
              <div className="relative">
                <div class="grid gap-6 mb-6 lg:grid-cols-2">
                  <div>
                    <h4>Creditor</h4>
                  </div>
                  <div>Arrears amount</div>
                  {/* Rent/Mortgage */}
                  <div>
                    <label
                      for="arrears_rent"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Rent/Mortgage/Housing
                    </label>
                  </div>

                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="arrears_rent"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0"
                        onChange={onArrearsRent}
                        value={format({ truncate: 0 })(arrearsRent || 0)}
                      />
                    </div>
                  </div>

                  {/* Electricity */}
                  <div>
                    <label
                      for="arrears_electricity"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Electricity
                    </label>
                  </div>

                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="arrears_electricity"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0"
                        onChange={onArrearsElectricity}
                        value={format({ truncate: 0 })(arrearsElectricity || 0)}
                      />
                    </div>
                  </div>
                  {/* Gas */}
                  <div>
                    <label
                      for="arrears_gas"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Gas
                    </label>
                  </div>

                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="arrears_gas"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0"
                        onChange={onArrearsGas}
                        value={format({ truncate: 0 })(arrearsGas || 0)}
                      />
                    </div>
                  </div>
                  {/* Water */}
                  <div>
                    <label
                      for="arrears_water"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Water
                    </label>
                  </div>

                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="arrears_water"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0"
                        onChange={onArrearsWater}
                        value={format({ truncate: 0 })(arrearsWater || 0)}
                      />
                    </div>
                  </div>
                  {/* Council Tax */}
                  <div>
                    <label
                      for="arrears_council_tax"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Council Tax
                    </label>
                  </div>

                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="arrears_council_tax"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0"
                        onChange={onArrearsCouncilTax}
                        value={format({ truncate: 0 })(arrearsCouncilTax || 0)}
                      />
                    </div>
                  </div>
                  {/* ---- */}
                  {/* Loans/Credit */}
                  <div>
                    <label
                      for="arrears_credit"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Loans or credit cards
                    </label>
                  </div>

                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="arrears_credit"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0"
                        onChange={onArrearsCredit}
                        value={format({ truncate: 0 })(arrearsCredit || 0)}
                      />
                    </div>
                  </div>
                  {/* Mobile */}
                  <div>
                    <label
                      for="arrears_mobile"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Mobile contract
                    </label>
                  </div>

                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="arrears_mobile"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0"
                        onChange={onArrearsMobile}
                        value={format({ truncate: 0 })(arrearsMobile || 0)}
                      />
                    </div>
                  </div>
                  {/* Other */}
                  <div>
                    <label
                      for="arrears_other"
                      class="block pt-2 text-base font-medium text-gray-900 dark:text-gray-300"
                    >
                      Other
                    </label>
                  </div>

                  <div>
                    <div class="relative">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <span className="text-1xl">&pound;</span>
                      </div>
                      <input
                        type="text"
                        inputmode="numeric"
                        id="arrears_other"
                        onFocus={(e) =>
                          e.target.value === "0" ? (e.target.value = "") : null
                        }
                        className="bg-gray-50 border pl-8 lg:pl-10 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                        placeholder="0"
                        onChange={onArrearsOther}
                        value={format({ truncate: 0 })(arrearsOther || 0)}
                      />
                    </div>
                  </div>
                  {/* ---- */}
                  {/* Total Arrears */}
                  <div className="col-span-2 rounded bg-slate-300 p-4">
                    <div className="flex">
                      <span className="flex-1 text-lg text-red-900">
                        Your total arrears
                      </span>
                      <span className="flex-1 text-lg text-red-900 text-right">
                        {format({ prefix: "£" })(
                          parseInt(arrearsRent || 0) +
                            parseInt(arrearsElectricity || 0) +
                            parseInt(arrearsGas || 0) +
                            parseInt(arrearsCouncilTax || 0) +
                            parseInt(arrearsMobile || 0) +
                            parseInt(arrearsCredit || 0) +
                            parseInt(arrearsOther || 0)
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-1/3 pr-10 text-base"> </div>
            <div className="p-8 bg-slate-100 rounded-lg mt-8">
              <h3 class="block w-full text-2xl text-gray-900 font-medium title-font mb-4 leading-none">
                Your referral
              </h3>
              <p className="text-base mb-8">
                We've already set your referral as a Charity Shop Gift Card
                loan. You don't need to do anything in this section.
              </p>
              <div class="grid gap-6 mb-6 grid-cols-1">
                <div>
                  <label
                    for="referral"
                    class="block mb-2 text-base font-medium text-gray-900 dark:text-gray-300"
                  >
                    Where your referral came from
                  </label>
                  <select
                    id="referral"
                    name="referral"
                    className="bg-gray-50 border p-3 border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                    value={formik.values.referral}
                    disabled={true}
                  >
                    <option value="csgc" selected>
                      Charity Shop Gift Card
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="w-1/3 pr-10 text-base"> </div>
            <div className="p-8 bg-slate-100 rounded-lg mt-8">
              <div className="flex justify-end">
                <button
                  class="mr-4 text-white bg-gray-300 border-0 py-2 px-8 focus:outline-none hover:bg-gray-600 rounded text-lg"
                  onClick={backHandler}
                >
                  Back
                </button>
                <button
                  type="submit"
                  class="disabled:opacity-50 text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </section>
      )}
    </>
  );
};

export default CharityShopStepTwo;
